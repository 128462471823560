<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <!-- @change="getCardPolicyList" -->
            <a-select
              placeholder="请选择"
              v-model:value="formState.cinemaId"
              style="width: 250px"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option
                v-for="item in cinemaAllList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="订单号" name="orderNo" class="ui-form__item">
            <a-input
              v-model:value="formState.orderNo"
              placeholder="请输入订单号"
            ></a-input>
          </a-form-item>
          <a-form-item label="卡号" name="cardNumber" class="ui-form__item">
            <a-input
              v-model:value="formState.cardNumber"
              placeholder="请输入卡号"
            ></a-input>
          </a-form-item>

        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="变动用户手机号" name="phone" class="ui-form__item">
            <a-input
              v-model:value="formState.phone"
              placeholder="请输入变动用户手机号"
            ></a-input>
          </a-form-item>
          <a-form-item label="类型" name="type" class="ui-form__item">
            <a-select
              v-model:value="formState.type"
              style="width: 190px"
              placeholder="请选择类型"
            >
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">影片购买</a-select-option>
              <a-select-option :value="2">购买卖品</a-select-option>
              <a-select-option :value="3">影片退款</a-select-option>
              <a-select-option :value="4">卖品退款</a-select-option>
              <a-select-option :value="5">开卡</a-select-option>
              <a-select-option :value="6">小程序充值</a-select-option>
              <a-select-option :value="7">后台充值</a-select-option>
              <a-select-option :value="8">充值退款</a-select-option>

            </a-select>
          </a-form-item>

          <a-form-item label="发生时间" class="ui-form__item">
            <a-range-picker v-model:value="startTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['coupon_stored_balanceChangesReport_export']" style="margin-left: 20px;">
							<ExportReport type="cardRecord" :searchData="searchData"></ExportReport>
						</span>
            <a style="margin-left: 20px" @click="showAll = !showAll">
              {{ showAll ? "收起" : "展开" }}
              <Icon v-show="!showAll" icon="DownOutlined"></Icon>
              <Icon v-show="showAll" icon="UpOutlined"></Icon>
            </a>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px"
        :dataSource="list"
        :columns="columns"
        :pagination="pagination"
        :scroll="{ x: 1600 }"
      >
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'createTime'">
            <div>
              {{ transDateTime(record.createTime) }}
            </div>
          </template>
          <template v-if="column.key === 'type'">
            <span v-if="record.type == 1">影片购买</span>
            <span v-if="record.type == 2">购买卖品</span>
            <span v-if="record.type == 3">影片退款</span>
            <span v-if="record.type == 4">卖品退款</span>
            <span v-if="record.type == 5">开卡</span>
            <span v-if="record.type == 6">小程序充值</span>
            <span v-if="record.type == 7">后台充值</span>
            <span v-if="record.type == 8">充值退款</span>
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import {
  getOrganizationList,
  getCinemaList,
} from "@/service/modules/cinema.js";
import {
  cardChangesReport
} from "@/service/modules/coupon.js";
import ExportReport from "@/components/exportReport/exportReport.vue";
export default {
  components: { Icon, ExportReport},
  data() {
    return {
      loading: false,
      showAll: false,
      organizationList: [],
      cinemaAllList: [],
      cardAllList: [],
      formState: {
        cardType:3,
        type:''
      },
      startTime: [],
      searchData: {},
      list: [],
      columns: [
        {
          title: "所属影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    this.getOrganizationList();
  },
  methods: {
    onBack(isRef) {
      if (isRef) {
        this.getData();
      }
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
      this.searchData.type = this.searchData.type != ''
        ? this.searchData.type
        : undefined;
      if (this.startTime && this.startTime.length) {
        this.searchData.startCreateTime = this.startTime[0]
          .startOf("days")
          .unix();
        this.searchData.endCreateTime = this.startTime[1].endOf("days").unix();
      }
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.startTime = [];
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.onSearch();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await cardChangesReport({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getOrganizationList() {
      this.loading = true;
      try {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999,
        });
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
          this.onSearch();
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = 0;
      this.cardAllList = [];
      // this.formState.cardId = 0;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined,
      });
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}
</style>
